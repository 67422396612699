import { createApp } from 'vue'
import router from './routes'
import store from "./store";
import App from './App.vue'
import PrimeVue from 'primevue/config';
import GlobalComponents from './services/global-components';

import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css';
import '@/assets/styles/style.scss'

GlobalComponents.register(
    createApp(App).use(router).use(store).use(PrimeVue)
).mount('#app')

