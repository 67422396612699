<template>
  <div class="grid">
    <div class="col-12 lg:col-4 flex">
      <step-card>
        <div class="step-card-header">
          <div>
            <img alt="new merchant app" class="step-card-icon"
                 src="../assets/images/icon-merchant-application@2x.png">
          </div>
          <div class="step-card-title">
            <h2 class="mt-3">Hardware Order Form</h2>
          </div>
        </div>
        <div class="step-card-body">
          <p class="gray-text step-card-text">Complete this form to order hardware or provide the merchant with a final invoice or payment plan summary.
            Please note that it is critically important that all form fields are accurate.</p>
        </div>
        <div class="step-card-footer flex mt-4">
          <Button @click="goOrders" label="Go to Hardware Order Form" class="flex-1 p-button-lg"/>
        </div>
      </step-card>
    </div>
    <div class="col-12 lg:col-4 flex">
      <step-card>
        <div class="step-card-header">
          <div>
            <img alt="new merchant app" class="step-card-icon"
                 src="../assets/images/icon-merchant-application@2x.png">
          </div>
          <div class="step-card-title">
            <h2 class="mt-3">CC Authorization Form</h2>
          </div>
        </div>
        <div class="step-card-body">
          <p class="gray-text step-card-text">Download this form when collecting merchant’s credit card information in the field.</p>
        </div>
        <div class="step-card-footer flex mt-4">
          <Button label="Complete Site Survey" class="flex-1 p-button-lg"/>
        </div>
      </step-card>
    </div>
    <div class="col-12 lg:col-4 flex">
      <step-card>
        <div class="step-card-header">
          <div>
            <img alt="new merchant app" class="step-card-icon"
                 src="../assets/images/icon-merchant-application@2x.png">
          </div>
          <div class="step-card-title">
            <h2 class="mt-3">Hardware Quote Tool</h2>
          </div>
        </div>
        <div class="step-card-body">
          <p class="gray-text step-card-text">The Hardware Quote Tool is a simple spreadsheet that calculates the cost of hardware without having to complete a Hardware Order Form.</p>
        </div>
        <div class="step-card-footer flex mt-4">
          <Button label="Order Equipment" class="flex-1 p-button-lg"/>
        </div>
      </step-card>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import StepCard from '@/components/NewMerchantApplication/StepCard';

export default {
  components: {
    StepCard,
    Button,
  },
  methods: {
    goOrders() {
      window.location = '/orders'
    }
  },
};
</script>