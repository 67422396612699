<template>
    <reset-password-form/>
</template>

<script>
import ResetPasswordForm from '../components/Auth/ResetPasswordForm'
export default {
  components: {
    ResetPasswordForm,
  },
};
</script>

