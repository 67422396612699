import ApiService from '../api.service';

class Equipments {
    getAll() {
        return ApiService.request('get', 'equipment');
    }

    getAllOrders(merchantNumber) {
        return ApiService.request('get', 'equipment/' + merchantNumber + '/orders');
    }

    getTemplate(deviceName) {
        return ApiService.request('get', 'equipment/' + deviceName);
    }

    getOrder(merchantNumber, orderId) {
        return ApiService.request('get', 'equipment/' + merchantNumber + '/orders/' + orderId);
    }

    submitOrder(merchantNumber, data) {
        return ApiService.request('post', 'equipment/' + merchantNumber + '/orders', data);
    }

    resubmitOrder(merchantNumber, orderId, survey) {
        return ApiService.request('patch', 'equipment/' + merchantNumber + '/orders/' + orderId, {survey: survey});
    }
}

export default new Equipments();