<template>
  <form id="reset-password-form" @submit="handleRestore">
    <div id="subheader-text">Welcome to Parsepay</div>
    <div id="header-text" class="mb-4">Reset password</div>
    <Message severity="error" v-if="error">
      {{ error }}
    </Message>
    <Message severity="success" v-if="success">
      Password successfully updated
    </Message>
    <div class="p-field mb-3">
      <InputText
          id="email"
          :class="{ 'p-invalid': errors && errors['email'] }"
          type="email"
          v-model="email"
          placeholder="Email"
      />
      <small v-if="errors && errors['email']" id="email-help" class="p-error">{{ errors['email'] }}</small>
    </div>
    <div class="p-field mb-3">
      <InputText id="password" type="password" v-model="password" placeholder="Password"/>
      <small v-if="errors && errors['password']" id="password-help" class="p-error">{{
          errors['password']
        }}</small>
    </div>
    <div class="p-field mb-4">
      <InputText id="password2" type="password" v-model="password2" placeholder="Confirm password"/>
      <small v-if="errors && errors['password2']" id="password2-help" class="p-error">{{
          errors['password2']
        }}</small>
    </div>
    <Button :disabled="loading" type="submit" class="mb-3 btn-blue" label="Update password"/>
    <div class="helper-text-1">
      <router-link to="/login" class="no-underline">Return to login</router-link>
    </div>
  </form>
</template>

<script>
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Message from 'primevue/message';
import AuthService from '@/services/auth.service';

export default {
  name: 'ResetPassword',
  components: {
    InputText,
    Button,
    Message,
  },
  data() {
    return {
      loading: false,
      success: false,
      error: '',
      errors: [],
      password: '',
      password2: '',
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if(this.loggedIn) {
      this.$router.push('/');
    }
  },
  methods: {
    handleRestore(e) {
      e.preventDefault();
      this.loading = true;
      this.success = false;
      this.error = '';
      this.errors = [];

      AuthService.reset({
        email: this.email,
        password: this.password,
        password_confirmation: this.password2,
        token: this.$route.params.token,
      }).then(
          (result) => {
            this.loading = false;
            if(result && result.success) {
              this.success = true;
            } else {
              this.error = 'Password reset link is invalid or has expired. Please, try again';
            }
            this.error = (result && result.data && result.data.message) || this.error;
            this.errors = (result && result.data && result.data.errors) || [];
          },
      );
    },
  },
};
</script>

