<template>
  <Products/>
</template>

<script>
import Products from '../components/Products/Products'

export default {
  components: {
    Products
  }
}
</script>
