<template>
  <full-screen-modal>
    <merchant-app v-if="application" :merchant-number="this.application.merchant_number"/>
  </full-screen-modal>
</template>

<script>
import MerchantApp from '@/components/Application/Main';
import FullScreenModal from '@/components/FullScreenModal';

export default {
  name: 'Modal application',
  components: {
    MerchantApp,
    FullScreenModal
  },
  props: {
    application: Object,
  },
};
</script>