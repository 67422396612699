<template>
    <ForgotPasswordForm/>
</template>

<script>
import ForgotPasswordForm from '@/components/Auth/ForgotPasswordForm';

export default {
  components: {
    ForgotPasswordForm,
  },
};
</script>
