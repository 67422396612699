<template>
  <div id="auth-layout" class="grid m-0">
    <div id="auth-panel-container" class="col-12 md:col-8 p-fluid">
      <auth-card>
        <router-view></router-view>
      </auth-card>
    </div>
    <div id="auth-banner" class="hidden md:flex md:col-4 p-fluid">
      <img id="auth-banner-logo" alt="parsepay logo" src="../assets/images/logo-white-2x.png">
    </div>
  </div>
</template>

<script>
import AuthCard from '@/components/AuthCard';
export default {
  components: {AuthCard},
};
</script>