<template>
    <quote :order-id="parseInt(this.$route.params.orderId)"/>
</template>

<script>
import Quote from '../components/Orders/Quote'

export default {
  components: {
    Quote,
  },
};
</script>

