<template>
  <main-layout>
    <h2>404</h2>
    <p>Page not found.</p>
  </main-layout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout'

export default {
  components: {
    MainLayout
  }
}
</script>
