<template>
  <page title="Application">
    <merchant-app/>
  </page>
</template>

<script>
import MerchantApp from '@/components/Application/Customer';
import Page from '@/components/Page'

export default {
  name: 'Application',
  components: {
    MerchantApp,
    Page
  },
  data() {
    return {

    };
  },
};
</script>