<template>
    <InviteUser/>
</template>

<script>
import InviteUser from '../components/InviteUser.vue'

export default {
  components: {
    InviteUser
  }
}
</script>
