<template>
    <login-form :is-customer="false"/>
</template>

<script>
import LoginForm from '../components/Auth/LoginForm'

export default {
  components: {
    LoginForm,
  },
};
</script>

