<template>
  <login-form :is-customer="true"/>
</template>

<script>
import LoginForm from '../components/Auth/LoginForm'

export default {
  components: {
    LoginForm,
  },
};
</script>

