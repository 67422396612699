import ApiService from '../api.service';

class Merchants {
    async getAll(itemsPerPage = 10, currentPage = 1) {
        return await ApiService.request(
            'get',
            'merchant/',
            {"per_page": itemsPerPage, "page": currentPage});

    }
}

export default new Merchants();