<template>
  <div id="applications-list-table">
    <DataTable :value="items" responsiveLayout="scroll"
               :lazy="true" :paginator="true" :rows="itemsPerPage" :totalRecords="total"
               :loading="loading"
               @page="onPage($event)"
               paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
               currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
      <template #empty>
        <div class="p-3">
          No Orders found
        </div>
      </template>
      <Column field="parse_pos_user_id" header="Merchant ID"/>
      <Column field="name" header="Name"/>
      <Column field="email" header="Email"/>
      <Column field="phone" header="Phone"/>

      <Column class="mobile-hide" field="created_at" header="Application">
        <template #body="slotProps">
          <div v-if="slotProps.data.merchant_number" class="p-3">
            <Button @click="showApplication(slotProps.data.merchant_number)" class="p-button-lg p-button-success w-full md:w-auto flex justify-content-center">
              Show Application
            </Button>
          </div>
          <div class="p-3" v-else>
            No Application
          </div>
        </template>
      </Column>
      <Column class="mobile-hide" field="created_at" header="Order">
        <template #body="slotProps">
          <div v-if="slotProps.data.order_id" class="p-3">
            <Button @click="goQuote(slotProps.data.order_id)" class="p-button-lg p-button-success w-full md:w-auto flex justify-content-center">
              Quote
            </Button>
          </div>
          <div class="p-3" v-else>
            No Orders
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
  <merchant-app-modal
      :application="selectedApplication"
      v-show="selectedApplication"
      @close="selectedApplication = null"
  />
</template>

<script>
import MerchantsService from "@/services/api-calls/merchants.service";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import MerchantAppModal from '@/components/Application/MainModal';
import Button from 'primevue/button';

export default {
  components: {
    Column,
    DataTable,
    MerchantAppModal,
    Button,
  },
  created() {
    this.getItems();
  },

  data() {
    return {
      items: [],
      error: null,
      loading: true,
      total: 0,
      first: 1,
      last: 0,
      currentPage: 0,
      itemsPerPage: 10,
      selectedApplication: null,
    };
  },
  methods: {
    showOrderActions(orderId, status, e) {
      this.activeOrder = orderId;
      this.activeOrderStatus = status;
      this.$refs.orderActions.toggle(e);
    },
    showApplication(number) {
      this.selectedApplication = {merchant_number: number};
    },
    onPage(event) {
      this.currentPage = event.page;
      this.getItems();
    },
    goQuote(id) {
      window.location = '/order/' + id + '/quote'
    },
    async getItems() {
      this.loading = true;

      const data = await MerchantsService.getAll(this.itemsPerPage, this.currentPage + 1);
      this.items = data.items ?? []
      this.total = data.total
      this.error = data.error ?? null
      this.loading = false;
    },
  },
}
</script>
