<template>
  <div class="flex flex-column lg:flex-row flex-1 w-full">
    <Dropdown
        :showClear="true"
        v-model="option"
        :options="options"
        @change="changeFilter"
        optionLabel="name"
        optionValue="value"
        inputId="application-filters"
        id="application-filters-dropdown"
        placeholder="Filter Results"
        class="lg:mr-4 mb-3 lg:mb-0 w-full md:w-auto"
    />
    <form action="#" @submit.prevent="changeFilter()">
      <div id="applications-searchbar-container" class="p-fluid lg:mr-6">
            <span class="p-input-icon-left p-input-icon-right">
              <i class="pi pi-search" @click="applyFilter"/>
              <InputText id="applications-searchbar" class="p-fluid" type="text" v-model="name"/>
              <i v-show="name" @click="clear" class="pi pi-times"/>
            </span>
      </div>
    </form>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';

export default {
  name: 'Filter',
  components: {
    InputText,
    Dropdown,
  },
  props: {
    options: Object,
  },
  methods: {
    clear() {
      this.name = null;
      this.$emit('changeFilter', {'option': this.option});
      this.clearFilter();
    },
    changeFilter() {
      this.$emit('changeFilter', {'name': this.name, 'option': this.option});
      return false;
    },
    applyFilter() {
      this.$emit('changeFilter', {'name': this.name, 'option': this.option});
      this.$emit('applyFilter');
      return false;
    },
    clearFilter() {
      this.$emit('clearFilter');
      return false;
    },
  },
  data() {
    return {
      name: null,
      option: null,
    };
  },
};
</script>