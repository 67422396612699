<template>
    <register-form/>
</template>

<script>
import RegisterForm from '../components/Auth/RegisterForm'

export default {
  components: {
    RegisterForm,
  },
};
</script>

