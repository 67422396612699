<template>
  <div id="app">
    <Header/>
    <div id="app-body">
      <div id="app-content">
        <router-view></router-view>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header';

export default {
  components: {
    Header,
  },
};
</script>
