<template>
  <div class="grid m-0 flex flex-1">
    <small v-if="error" class="p-error">{{error}}</small>
    <div class="col-12 lg:px-2">
      <div class="p-field mb-2 flex flex-column">
        <label for="name" class="mb-2">Category Name</label>
        <InputText
            id="name"
            type="text"
            v-model="data.name"
            getFormatter=""
            :disabled="isLoading"
            :class="{'p-invalid': errors.name }"
            aria-describedby="name_help"
        />
        <small id="name_help" v-if="errors.name" class="p-error">{{ errors.name[0] }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext';

export default {
  name: 'CreateCategoryForm',
  components: {
    InputText,
  },
  updated() {
    this.data = this.category
  },
  props: {
    isLoading: Boolean,
    errors: Object,
    error: String,
    category: Object,
  },
  emits: ['update:category'],
  watch: {
    data: {
      handler() {
        this.$emit('update:category', this.data)
      },
      deep: true
    },
  },
  data() {
    return {
      data: this.category
    };
  },
};
</script>