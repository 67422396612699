<template>
  <general-form
      :isActive="'general' === activeForm"
      :application="application"
      @close="close"
      @open="open($event)"
      @update="update($event)"
  />
  <business-form
      :isActive="'business' === activeForm"
      :application="application"
      @close="close"
      @open="open($event)"
      @update="update($event)"
  />
  <profile-form
      :isActive="'profile' === activeForm"
      :application="application"
      @close="close"
      @open="open($event)"
      @update="update($event)"
  />
  <banking-form
      :isActive="'banking' === activeForm"
      :application="application"
      @close="close"
      @open="open($event)"
      @update="update($event)"
  />
  <contacts-form
      :isActive="'contacts' === activeForm"
      :application="application"
      @close="close"
      @open="open($event)"
      @update="update($event)"
  />
  <survey-form
      :is-active="'survey' === activeForm"
      :application="application"
      @close="close"
      @update="update($event)"
  />
  <equipments-form
      :is-active="'equipment' === activeForm"
      :merchant-number="application.merchant_number"
      @close="close"
      @update="update($event)"
  />
</template>

<script>
import GeneralForm from '@/components/Application/Forms/General';
import BusinessForm from '@/components/Application/Forms/Business';
import BankingForm from '@/components/Application/Forms/Banking';
import ProfileForm from '@/components/Application/Forms/Profile';
import ContactsForm from '@/components/Application/Forms/Contacts';
import SurveyForm from '@/components/Application/Forms/Survey';
import EquipmentsForm from '@/components/Application/Forms/Equipments';

export default {
  name: 'Forms',
  components: {
    GeneralForm,
    BusinessForm,
    ProfileForm,
    BankingForm,
    ContactsForm,
    SurveyForm,
    EquipmentsForm
  },
  emits: ['close', 'open', 'update'],
  methods: {
    close() {
      this.$emit('close');
    },
    open($event) {
      this.$emit('open', $event);
    },
    update($event) {
      this.$emit('update', $event);
    },
  },
  props: {
    application: Object,
    activeForm: String,
  },
};
</script>