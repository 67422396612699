<template>
  <DocumentItems />
</template>

<script>
import DocumentItems from '../components/DocumentItems.vue';

export default {
  components: {
    DocumentItems,
  },
};
</script>
