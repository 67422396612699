<template>
  <form id="forgot-password-form" @submit="handleForgot">
    <div id="subheader-text">Welcome to Parsepay</div>
    <div id="header-text" class="mb-4">Forgot password?</div>
    <Message severity="error" v-if="error">
      {{ error }}
    </Message>
    <Message severity="success" v-if="success">
      Please, check your email.
    </Message>
    <div class="p-field mb-3">
      <InputText
          id="email"
          :class="{ 'p-invalid': errors && errors['email'] }"
          type="email"
          v-model="email"
          placeholder="Email"
      />
      <small v-if="errors && errors['email']" id="email-help" class="p-error">{{ errors['email'] }}</small>
    </div>
    <Button :disabled="loading" type="submit" class="mb-3 btn-blue" label="Request reset link"/>
    <div class="helper-text-1">
      <router-link to="/login" class="no-underline">Return to login</router-link>
    </div>
  </form>
</template>

<script>
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Message from "primevue/message";
import AuthService from "@/services/auth.service";

export default {
  components: {
    InputText,
    Button,
    Message
  },
  data() {
    return {
      loading: false,
      success: false,
      error: "",
      errors: [],
      email: "",
    };
  },
  methods: {
    handleForgot(e) {
      e.preventDefault();
      this.loading = true;
      this.success = false;
      this.error = '';
      this.errors = [];

      AuthService.forgot(this.email).then(
          (result) => {
            this.loading = false;
            this.success = (result && result.result) || false
            this.error = (result && result.message) || '';
            this.errors = (result && result.errors) || [];
          }
      );
    },
  },
}
</script>
