import Questions from '../components/Application/Forms/EquipmentConfiguration/Questions.vue';

class GlobalComponents {
    register(app) {
        app.component('Questions', Questions);

        return app;
    }

}

export default new GlobalComponents();