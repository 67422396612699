<template>
  <full-screen-modal v-show="isActive" id="equipments" title="Order Equipment">
    <div id="new-merchant-app-2">
      <div class="grid m-0 flex flex-1">
        <div class="col-12 px-0">
          <div class="step-body mt-2 lg:mt-0">
            <div class="grid mx-0 flex align-items-center mb-3 lg:mb-4">
              <div class="col-12 lg:col-9 px-0 lg:px-2">
                <div class="step-header mb-3">
                  Add equipment to the application.
                </div>
                <div class="gray-text mb-2 lg:mb-1">Select the card reader and configure it accordingly.
                </div>
              </div>
              <div class="col-12 lg:col-3 px-0 lg:px-2 flex justify-content-start lg:justify-content-end">
                <Button @click="isOpenEquipment = true" class="p-button-outlined p-button-outlined-shaded w-full sm:w-auto p-3 sm:p-2"
                        label="Add Equipment"/>
              </div>
            </div>
            <div>
              <div v-if="isLoading" class="text-center"><progress-spinner/></div>
              <div v-else-if="!orders.length" class="px-0 lg:px-2 mb-3">
                <div
                    class="merchant-equipment-empty-list flex flex-column align-items-center justify-content-center ">
                  <img alt="no equipment found" class="merchant-equipment-empty-icon"
                       src="../../../assets/images/no-equipment-found-icon@2x.png">
                  <p>Whoops! No equipment found.</p>
                </div>
              </div>
              <div v-else v-for="order in orders" v-bind:key="order.orderId" class="px-0 lg:px-2 mb-3">
                <div
                    class="step-contacts-list-item flex align-items-center justify-content-between">
                  <div class="flex flex-column">
                    <div class="equipment-item-label">
                      Model
                    </div>
                    <div class="equipment-item-value">
                      {{ order.model }}
                    </div>
                  </div>
                  <div class="hidden lg:flex flex-column">
                    <div class="equipment-item-label">
                      Order #
                    </div>
                    <div class="equipment-item-value">
                      {{ order.orderId }}
                    </div>
                  </div>
                  <div class="hidden lg:flex flex-column">
                    <div class="equipment-item-label">
                      Tracking
                    </div>
                    <div class="equipment-item-value">
                      {{ order.status }}
                    </div>
                  </div>
                  <div class="flex flex-column">
                    <div class="equipment-item-label">
                      QTY
                    </div>
                    <div class="equipment-item-value">
                      {{ order.qty }}
                    </div>
                  </div>
                  <div>
                    <Button @click="selectOrder(order.orderId)"
                        class="p-button-secondary modal-close-btn btn-no-border equipment-item-options-btn">
                      <i class="pi pi-ellipsis-v"/>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <equipment-form
        :is-active="isOpenEquipment"
        @close="isOpenEquipment = false"
        @selectEquipment="selectEquipment($event)"
    />
    <equipment-configuration
        v-if="equipmentOrder || selectedOrder"
        :is-active="isOpenEquipmentConfiguration "
        :order-id="selectedOrder"
        :merchantNumber="merchantNumber"
        :equipment-order="equipmentOrder"
        @close="isOpenEquipmentConfiguration = false; selectedOrder = null; equipmentOrder = null"
        @submit="submitOrder($event)"
    />
  </full-screen-modal>
</template>

<script>
import FullScreenModal from '@/components/FullScreenModal';
import Button from 'primevue/button';
import EquipmentForm from '@/components/Application/Forms/Equipment';
import EquipmentConfiguration from '@/components/Application/Forms/EquipmentConfiguration/EquipmentConfiguration';
import EquipmentsService from "@/services/api-calls/equipments.service";
import FormHelper from "@/services/form.helper";
import ProgressSpinner from 'primevue/progressspinner';

export default {
  components: {
    FullScreenModal,
    Button,
    EquipmentForm,
    EquipmentConfiguration,
    ProgressSpinner
  },
  props: {
    merchantNumber: Number,
    isActive: Boolean,
  },
  watch: {
    isActive(isActive) {
      if(isActive) {
        this.getOrders();
      }
    },
    merchantNumber() {
      this.getOrders();
    },
    equipmentOrder() {
      if (this.equipmentOrder && this.equipmentOrder.equipment && this.equipmentOrder.quantity) {
        this.$nextTick(() => {
          this.isOpenEquipmentConfiguration = true
        })
      }
    },
    selectedOrder() {
      if (this.selectedOrder) {
        this.$nextTick(() => {
          this.isOpenEquipmentConfiguration = true
        })
      }
    }
  },
  methods: {
    async getOrders() {
      this.isLoading = true;
      const result = await EquipmentsService.getAllOrders(this.merchantNumber);
      this.orders = result.orders ?? []
      this.error = result.error ? FormHelper.convertGatewayError(result) :  null;
      this.isLoading = false;
    },
    selectEquipment(equipmentOrder) {
      if (equipmentOrder.equipment && equipmentOrder.quantity) {
        this.equipmentOrder = equipmentOrder
      }
    },
    selectOrder(orderId) {
      this.equipmentOrder = null;
      this.selectedOrder = orderId;
    },
    submitOrder(application) {
      this.isOpenEquipmentConfiguration = false;
      this.isOpenEquipment = false;
      this.equipmentOrder = null;
      this.$emit('update', application);
      this.getOrders();
    },
  },
  data() {
    return {
      isOpenEquipment: false,
      isOpenEquipmentConfiguration: false,
      isLoading: false,
      error: null,
      equipmentOrder: null,
      selectedOrder: null,
      orders: [],
      errors: {},
    };
  },
};
</script>