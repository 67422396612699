<template>
  <Forms/>
</template>

<script>
import Forms from '../components/Forms.vue'

export default {
  components: {
    Forms
  }
}
</script>
