<template>
  <div v-for="(question, index) in questions.questions" v-bind:key="index" class="col-12 px-0 lg:px-2">
    <div v-if="!question.conditionalOnAnswer || question.conditionalOnAnswer === parentValue" class="flex flex-column">
      <Question :question-key="questions.area + '-' + index" :question=question :errors="errors" @updateQuestion="this.updateQuestion($event, index)" />
    </div>
  </div>
</template>
<script>
import Question from './Question.vue';

export default {
  name: 'Questions',
  components: {
    Question
  },
  mounted() {
  },
  props: {
    errors: Object,
    questions: Object,
    parentValue: [String, Number],
  },
  methods: {
    updateQuestion(question, key) {
      let questions = this.questions.questions;
      questions[key] = question;
      this.$emit('updateConfiguration', questions);
    },
  },
  emits: ['updateConfiguration'],
};
</script>