<template>
  <div class="modal-backdrop">
    <div class="modal full-screen-modal">
      <header class="modal-header">
        <Button
            type="button"
            class="p-button-secondary modal-close-btn"
            @click="close"
        >
          <i class="pi pi-times"/>
        </Button>
      </header>

      <section class="modal-body">
        <slot></slot>
      </section>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';

export default {
  name: 'Modal',
  components: {
    Button
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>